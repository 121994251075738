
import {
  AnalyticsEvent,
  WidgetType,
  RessourceByType,
} from "@evercam/shared/types"
import ProjectsAutocomplete from "@/components/ProjectsAutocomplete"
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import LiveWidgetSettings from "@/components/widgets/settings/LiveWidgetSettings"
import RecordingsWidgetSettings from "@/components/widgets/settings/RecordingsWidgetSettings"
import BimCompareWidgetSettings from "@/components/widgets/settings/BimCompareWidgetSettings"
import VideoWallSettings from "@/components/widgets/settings/VideoWallSettings"
import WidgetPreview from "@/components/widgets/WidgetPreview"
import { CameraFeatureFlag, CameraStatus } from "@evercam/shared/types/camera"

export default {
  components: {
    ProjectsAutocomplete,
    CamerasAutocomplete,
    LiveWidgetSettings,
    RecordingsWidgetSettings,
    BimCompareWidgetSettings,
    WidgetPreview,
    VideoWallSettings,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resolutionType: [
        { text: "%", value: "%" },
        { text: "px", value: "px" },
        { text: "cm", value: "cm" },
        { text: "Inches", value: "in" },
        { text: "em", value: "em" },
      ],
      settingsComponents: {
        [WidgetType.LiveWidget]: "LiveWidgetSettings",
        [WidgetType.Recording]: "RecordingsWidgetSettings",
        [WidgetType.BimCompare]: "BimCompareWidgetSettings",
        [WidgetType.VideoWall]: "VideoWallSettings",
      },
      loading: false,
      editValueDebounce: null,
      editValueDebounceTimeOut: 400,
    }
  },
  computed: {
    widgetForm: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    ressourceType() {
      if (RessourceByType.camera.includes(this.widgetForm.widgetType)) {
        return "camera"
      } else if (RessourceByType.project.includes(this.widgetForm.widgetType)) {
        return "project"
      } else {
        return null
      }
    },
    formatedWidgetTypes() {
      return Object.values(WidgetType)
    },
  },
  watch: {
    "widgetForm.widgetType"(newValue, oldValue) {
      if (!oldValue || !this.widgetForm) {
        return
      }

      const widgetEventMap = {
        [WidgetType.BimCompare]: AnalyticsEvent.WidgetsSelectTypeBIMCompare,
        [WidgetType.LiveWidget]: AnalyticsEvent.WidgetsSelectTypeLiveView,
        [WidgetType.Recording]: AnalyticsEvent.WidgetsSelectTypeRecordings,
        [WidgetType.VideoWall]: AnalyticsEvent.WidgetsSelectTypeVideoWall,
      }
      const eventName = widgetEventMap[newValue]
      if (eventName) {
        this.$analytics.saveEvent(eventName)
      }

      const settings = this.widgetForm.settings
      Object.keys(settings).forEach((key) => {
        if (
          !["width", "resolution", "height", "heightResolution"].includes(key)
        ) {
          this.$set(settings, key, undefined)
        }
      })

      this.softReset()
    },
    "widgetForm.ressources.camera"(newValue, oldValue) {
      if (!oldValue || !this.widgetForm || oldValue == newValue) {
        return
      }

      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSelectCamera, {
        widget: this.widgetForm.widgetType,
        selectedCamera: newValue?.exid,
      })
    },
    "widgetForm.settings.height"(newValue, oldValue) {
      if (!oldValue || !this.widgetForm) {
        return
      }
      clearTimeout(this.editValueDebounce)
      this.editValueDebounce = setTimeout(() => {
        this.$analytics.saveEvent(AnalyticsEvent.WidgetsEditHeight, {
          widget: this.widgetForm.widgetType,
          height: newValue,
        })
      }, this.editValueDebounceTimeOut)
    },
    "widgetForm.settings.heightResolution"(newValue, oldValue) {
      if (!oldValue || !this.widgetForm) {
        return
      }
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsEditHeightResolutionType,
        {
          widget: this.widgetForm.widgetType,
          heightResolution: newValue,
        }
      )
    },
    "widgetForm.settings.width"(newValue, oldValue) {
      if (!oldValue || !this.widgetForm) {
        return
      }
      clearTimeout(this.editValueDebounce)
      this.editValueDebounce = setTimeout(() => {
        this.$analytics.saveEvent(AnalyticsEvent.WidgetsEditWidth, {
          widget: this.widgetForm.widgetType,
          width: newValue,
        })
      }, this.editValueDebounceTimeOut)
    },
    "widgetForm.settings.resolution"(newValue, oldValue) {
      if (!oldValue || !this.widgetForm) {
        return
      }
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsEditWidthResolutionType, {
        widget: this.widgetForm.widgetType,
        widthResolution: newValue,
      })
    },
  },
  methods: {
    onChangeIsPublic(value) {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsTogglePublicWidget, {
        widget: this.widgetForm.widgetType,
        isPublic: value,
        isAllowedOrigin: !value,
        allowedOrigin: !value ? this.widgetForm.allowedOrigin : "",
      })
    },
    setDialogState(event) {
      if (!event) {
        this.$analytics.saveEvent(AnalyticsEvent.WidgetsCancelClick)
        this.widgetForm = null
      } else {
        this.$analytics.saveEvent(AnalyticsEvent.WidgetsSaveClick)
        this.$emit("save", this.widgetForm)
      }
    },
    filterCameras(cameras) {
      let result = []
      if (this.widgetForm.widgetType === WidgetType.BimCompare) {
        result = cameras.filter((camera) =>
          camera?.featureFlags?.includes(CameraFeatureFlag.BimCompare)
        )
      } else if (this.widgetForm.widgetType === WidgetType.Recording) {
        result = cameras.filter(
          (camera) =>
            ![CameraStatus.WaitingForSiteVisit].includes(camera.status)
        )
      } else {
        result = cameras.filter(
          (camera) =>
            ![
              CameraStatus.Decommissioned,
              CameraStatus.WaitingForSiteVisit,
            ].includes(camera.status)
        )
      }

      if (
        !result?.find((c) => c.exid === this.widgetForm.ressources.camera?.exid)
      ) {
        this.widgetForm.ressources.camera = null
      }

      return result
    },
    settingsChanged(settings) {
      this.widgetForm.settings = settings
    },
    softReset() {
      // small hack to fix filtered autocomplete items being persistent between widget types
      this.loading = true
      this.$setTimeout(() => (this.loading = false), 100)
    },
  },
}
