
import {
  AnalyticsEvent,
  WidgetType,
  DelayUnit,
  RecordingsWidgetSettingsKeys,
  CameraStatus,
} from "@evercam/shared/types"

export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      delayUnitItems: [
        { text: this.$t("content.minutes"), value: DelayUnit.Minute },
        { text: this.$t("content.hours"), value: DelayUnit.Hour },
        { text: this.$t("content.days"), value: DelayUnit.Day },
      ],
      settings: {
        hasDelay: false,
        delay: 5,
        delayUnit: DelayUnit.Minute,
        darkMode: false,
        calendar: false,
      },
      editValueDebounce: null,
      editValueDebounceTimeOut: 400,
    }
  },
  computed: {
    isCameraDecommissioned(): boolean {
      return (
        this.widget.ressources?.camera?.status === CameraStatus.Decommissioned
      )
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
    isCameraDecommissioned: {
      immediate: true,
      handler(value) {
        if (value) {
          this.settings.hasDelay = false
        }
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (
        Object.values(RecordingsWidgetSettingsKeys)?.includes(key) &&
        this.widget.settings[key] !== undefined
      ) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    onDarkModeChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRecordingsDarkMode,
        {
          widget: WidgetType.Recording,
          hasDarkMode: this.settings.darkMode,
        }
      )
    },
    onDelayChange() {
      clearTimeout(this.editValueDebounce)
      this.editValueDebounce = setTimeout(() => {
        this.saveDelayAnalyticsEvent()
      }, this.editValueDebounceTimeOut)
    },
    onDelayUnitChange() {
      this.saveDelayAnalyticsEvent()
    },
    onHasDelayChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRestrictRecentImages,
        {
          widget: WidgetType.Recording,
          hasDelay: this.settings.hasDelay,
        }
      )
    },
    onSidebarChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRecordingsCalendar,
        {
          widget: WidgetType.Recording,
          hasCalendar: this.settings.calendar,
        }
      )
    },
    saveDelayAnalyticsEvent() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingRestrictRecentImagesInput,
        {
          widget: WidgetType.Recording,
          delay: this.settings.delay,
          delayUnit: this.settings.delayUnit,
        }
      )
    },
  },
}
