
import {
  AnalyticsEvent,
  CameraStatus,
  RessourceByType,
  WidgetType,
} from "@evercam/shared/types"
import { decamelize } from "humps"
import { updateQuery, toTitleCase, formatTime } from "@evercam/shared/utils"
import {
  CommonWidgetSettingsKeys,
  RecordingsWidgetSettingsKeys,
  LiveViewWidgetSettingsKeys,
  VideoWallWidgetSettingsKeys,
} from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { useCameraStore } from "@/stores/camera"
import { mapStores } from "pinia"

import CameraRessource from "@/components/widgets/ressources/CameraRessource"
import ProjectRessource from "@/components/widgets/ressources/ProjectRessource"
import WidgetForm from "@/components/widgets/WidgetForm"
import WidgetCredentials from "@/components/widgets/WidgetCredentials"
import DefaultPageWrapper from "@/components/DefaultPageWrapper"

export default {
  name: "Widgets",
  components: {
    CameraRessource,
    ProjectRessource,
    WidgetForm,
    WidgetCredentials,
    DefaultPageWrapper,
  },
  data() {
    return {
      options: {},
      widgets: [],
      total: 0,
      loading: false,
      sortBy: "inserted_at",
      sortDesc: true,
      optionsWatcherEnabled: false,
      isLoading: false,
      ressourceComponents: {
        camera: "CameraRessource",
        project: "ProjectRessource",
      },
      headers: [
        {
          value: "widgetType",
          text: this.$t("content.widgets.headers.widgetType"),
          class: "subtitle-1 table-header",
          align: "center",
          visible: true,
          width: 100,
          sortable: false,
        },
        {
          value: "ressources",
          text: this.$t("content.widgets.headers.ressources"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 250,
          sortable: false,
        },
        {
          value: "allowedOrigin",
          text: this.$t("content.widgets.headers.origin"),
          class: "subtitle-1 table-header",
          align: "center",
          cellClass: "origin",
          visible: true,
          width: 100,
        },
        {
          value: "settings",
          text: this.$t("content.widgets.headers.settings"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 320,
          sortable: false,
        },
        {
          value: "active",
          text: "Status",
          class: "subtitle-1 table-header",
          align: "center",
          visible: true,
          width: 100,
        },
        {
          value: "actions",
          text: "Actions",
          class: "subtitle-1 table-header",
          align: "center",
          visible: true,
          width: 50,
          sortable: false,
        },
      ],
      editedWidget: null,
      shownCredentialsWidget: null,
    }
  },
  computed: {
    ...mapStores(useCameraStore),
  },
  watch: {
    options: {
      handler() {
        if (this.optionsWatcherEnabled) {
          this.getWidgets()
        }
        this.optionsWatcherEnabled = true
      },
      deep: true,
    },
  },
  mounted() {
    this.getWidgets()
  },
  methods: {
    async getWidgets(params = {}) {
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        sortBy = decamelize(sortBy[0])
        updateQuery(params)
        this.loading = true
        let response = await EvercamApi.widgets.index({
          ...params,
          sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
          limit: itemsPerPage,
          page: page,
        })

        this.widgets = response.items
        this.total = response.total
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.widgets.fetch_widgets_failed").toString(),
          error,
        })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
    async deleteWidget(widget) {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.widgets.delete_widget_title"),
          message: this.$t("content.widgets.delete_widget_message"),
        })
      ) {
        try {
          await EvercamApi.widgets.delete(widget.id)
          this.$notifications.success(
            this.$t("content.widgets.delete_widget_success").toString()
          )
          this.getWidgets()
        } catch (error) {
          this.$notifications.error({
            text: `${this.$t("content.widgets.delete_widget_failed")}`,
            error,
          })
        }
      }
    },
    async openEditWidget(item) {
      this.isLoading = true
      try {
        this.editedWidget = await EvercamApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.widgets.fetch_widget_failed").toString(),
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    openCreateWidget() {
      const initialCameraRessource = this.cameraStore.cameras?.find(
        (camera) => camera.status !== CameraStatus.Decommissioned
      )
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsAddWidgetClick)
      this.editedWidget = {
        widgetType: WidgetType.LiveWidget,
        settings: {
          width: 100,
          resolution: "%",
          height: 100,
          heightResolution: "%",
        },
        ressources: {
          camera: initialCameraRessource ?? null,
        },
      }
    },
    async showWidgetLink(item) {
      this.isLoading = true
      try {
        this.shownCredentialsWidget = await EvercamApi.widgets.show(item.id)
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.widgets.fetch_widget_failed").toString(),
          error,
        })
      } finally {
        this.isLoading = false
      }
    },
    changeWidgetState({ id, active }) {
      this.save({ id, active: !active })
    },
    async save(w) {
      this.isLoading = true
      let widget = structuredClone(w)
      if (widget?.id) {
        await this.editWidget(widget)
      } else {
        await this.createWidget(widget)
      }
      this.editedWidget = null
      await this.getWidgets()
      this.isLoading = false
    },
    async createWidget(widget) {
      try {
        const { provider, id } = this.getCreateWidgetProvider(widget)
        this.shownCredentialsWidget = await provider(id, widget)
        this.$notifications.success(
          this.$t("content.widgets.create_widget_success").toString()
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.widgets.create_widget_failed").toString(),
          error,
        })
      }
    },
    async editWidget(widget) {
      try {
        this.formatRessources(widget)
        await EvercamApi.widgets.update(widget.id, widget)
        this.$notifications.success(
          this.$t("content.widgets.edit_widget_success").toString()
        )
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.widgets.edit_widget_failed")}`,
          error,
        })
      }
    },
    formatRessources(widget) {
      if (RessourceByType.project.includes(widget.widgetType)) {
        widget.ressources = { projectId: widget.ressources?.project?.id }
      } else if (RessourceByType.camera.includes(widget.widgetType)) {
        widget.ressources = { cameraId: widget.ressources?.camera?.exid }
      }
    },
    getCreateWidgetProvider(widget) {
      if (RessourceByType.project.includes(widget.widgetType)) {
        return {
          provider: EvercamApi.widgets.createProjectWidget,
          id: widget.ressources.project.exid,
        }
      } else if (RessourceByType.camera.includes(widget.widgetType)) {
        return {
          provider: EvercamApi.widgets.createCameraWidget,
          id: widget.ressources.camera.exid,
        }
      } else {
        throw new Error("Wrong ressource type")
      }
    },
    getSettings(settings) {
      const settingUnitMappings = {
        [CommonWidgetSettingsKeys.Height]:
          CommonWidgetSettingsKeys.HeightResolution,
        [CommonWidgetSettingsKeys.Width]:
          CommonWidgetSettingsKeys.WidthResolution,
        [RecordingsWidgetSettingsKeys.Delay]:
          RecordingsWidgetSettingsKeys.DelayUnit,
      }

      return Object.entries(settings).reduce((acc, [key, value]) => {
        if (
          (key === RecordingsWidgetSettingsKeys.Delay &&
            !settings[RecordingsWidgetSettingsKeys.HasDelay]) ||
          (key === RecordingsWidgetSettingsKeys.HasDelay && value)
        ) {
          return acc
        }
        if (typeof value === "boolean") {
          acc[toTitleCase(key)] = value
        } else if (settingUnitMappings[key]) {
          acc[
            `${toTitleCase(key)}: ${value} ${
              settings[settingUnitMappings[key]]
            }`
          ] = true
        } else if (
          [
            VideoWallWidgetSettingsKeys.RefreshRate,
            LiveViewWidgetSettingsKeys.Refresh,
          ].includes(key)
        ) {
          acc[`${toTitleCase(key)}: ${formatTime(value)}`] = true
        } else if (key === VideoWallWidgetSettingsKeys.Preset) {
          const cameras = (value || []).map((c) => c.camera).join(", ")
          acc[`${toTitleCase(key)}: (Cameras: ${cameras})`] = true
        } else if (!Object.values(settingUnitMappings).includes(key)) {
          acc[`${toTitleCase(key)}: ${value}`] = true
        }

        return acc
      }, {})
    },
  },
}
